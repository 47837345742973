import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { en, ru, tm } from '../../locales/langs';

const NavMenu = ({ categories }) => {
    const { i18n } = useTranslation();
    return (
        <div className={clsx("main-menu")}>
            <nav>
                <ul>
                    <li>
                        <Link to={"/"} style={{color: "white", fontSize: "1rem"}}>
                            {i18n.language === 'en'
                                ? en.home
                                : i18n.language === "ru"
                                    ? ru.home
                                    : tm.home
                            }
                        </Link>
                    </li>
                    <li>
                        <Link to="/shop-filter" style={{color: "white", fontSize: "1rem"}}>
                            {i18n.language === 'en'
                                ? en.products
                                : i18n.language === "ru"
                                    ? ru.products
                                    : tm.products
                            }
                            <i className="fa fa-angle-down" />
                        </Link>
                        <ul className="mega-menu mega-menu-padding">
                            {categories.map((category) => (
                                <li key={category.id}>
                                    <ul>
                                        <li className="mega-menu-title">
                                            <Link to={`/shop-filter/?temp=false&main_category=` + category.id}>
                                                {i18n.language === 'en'
                                                    ? category.title_en
                                                    : ""
                                                }
                                                {i18n.language === 'ru'
                                                    ? category.title_ru
                                                    : ""
                                                }
                                                {i18n.language === 'tm'
                                                    ? category.title_tm
                                                    : ""
                                                }
                                            </Link>
                                        </li>
                                        {category.subcategories.map((subcat) => (
                                            <li key={subcat.id}>
                                                <Link to={`/shop-filter/?temp=false&category=` + subcat.id}>
                                                    {i18n.language === 'en'
                                                        ? subcat.title_en
                                                        : ""
                                                    }
                                                    {i18n.language === 'ru'
                                                        ? subcat.title_ru
                                                        : ""
                                                    }
                                                    {i18n.language === 'tm'
                                                        ? subcat.title_tm
                                                        : ""
                                                    }
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}

                        </ul>
                    </li>
                    <li>
                        <Link to="/brands" style={{color: "white", fontSize: "1rem"}}>
                            {i18n.language === 'en'
                                ? en.brands
                                : i18n.language === "ru"
                                    ? ru.brands
                                    : tm.brands
                            }
                        </Link>
                    </li>
                    <li>
                        <Link to="/media" style={{color: "white", fontSize: "1rem"}}>
                            {i18n.language === 'en'
                                ? en.media
                                : i18n.language === "ru"
                                    ? ru.media
                                    : tm.media
                            }
                        </Link>
                    </li>
                    <li>
                        <Link to="/contacts" style={{color: "white", fontSize: "1rem"}}>
                            {i18n.language === 'en'
                                ? en.stores
                                : i18n.language === "ru"
                                    ? ru.stores
                                    : tm.stores
                            }
                        </Link>
                    </li>
                    <li>
                        <Link to="/about-us" style={{color: "white", fontSize: "1rem"}}>
                            {i18n.language === 'en'
                                ? en.about_us
                                : i18n.language === "ru"
                                    ? ru.about_us
                                    : tm.about_us
                            }
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default NavMenu;