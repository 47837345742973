import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from "../../../rootUrl";

const mainProductsUrl = backendUrl + "/api/products/product-main/";
const newProductsHomeUrl = backendUrl + "/api/products/product-list/?is_new=true";
const specialProductsHomeUrl = backendUrl + "/api/products/product-list/?is_special=true";
const discountProductsHomeUrl = backendUrl + "/api/products/product-list/?is_discount=true";
const categoriesListUrl = backendUrl + "/api/products/categories/";
const currentCourseUrl = backendUrl + "/api/products/course/";
const singleProductUrl = backendUrl + "/api/products/product-detail/";
const filterProductsUrl = backendUrl + "/api/products/product-list/";
const commentProductUrl = backendUrl + "/api/products/comment-create/";

const initialState = {
    mainProducts: [],
    newProductsHome: [],
    categories: [],
    homeProducts: [],
    course: 1,
    isLoading: false,
    isSuccess: false,
    singleProduct: [],
    filterProducts: [],
    isProductLoading: true,
    commentMessage: ""
};

export const createComment = createAsyncThunk(
    "product/createComment",
    async ({ token, commentData }, { rejectWithValue }) => {
        try {
            var config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + token
                }
            };
            const { data } = await axios.post(
                commentProductUrl,
                commentData,
                config
            );
            return data;
        } catch {
            rejectWithValue('something went wrong')
        }
    }
)

export const getFilterProducts = createAsyncThunk(
    'product/getFilterProducts',
    async (arg, { rejectWithValue }) => {
        const { search } = arg;
        console.log(filterProductsUrl + search);

        try {
            const { data } = await axios.get(filterProductsUrl + search);
            return data.data;
        } catch {
            rejectWithValue('something went wrong');
        }
    }
)

export const getSingleProduct = createAsyncThunk(
    'product/getSingleProduct',
    async (arg, { rejectWithValue }) => {
        const { id } = arg;
        const detailUrl = singleProductUrl + id + "/"
        try {
            const { data } = await axios.get(detailUrl)
            return data
        } catch {
            rejectWithValue('something went wrong');
        }
    }
)

export const getCurrentCourse = createAsyncThunk(
    'product/getCurrentCourse',
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(currentCourseUrl);
            return data['data']['course'];
        } catch {
            rejectWithValue('something went wrong');
        }
    }
)

export const getHomePageProducts = createAsyncThunk(
    'product/getHomePageProducts',
    async (arg, { rejectWithValue }) => {
        try {
            var resp = {};
            const special = await axios.get(specialProductsHomeUrl);
            resp["special"] = special.data["data"]["results"]
            const new_products = await axios.get(newProductsHomeUrl);
            resp["new"] = new_products.data["data"]["results"]
            const discount = await axios.get(discountProductsHomeUrl);
            resp["discount"] = discount.data["data"]["results"]

            return resp;
        } catch {
            rejectWithValue('something went wrong');
        }
    }
)

export const getMainProducts = createAsyncThunk(
    'product/getMainProducts',
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(mainProductsUrl)
            return data["data"];
        } catch (error) {
            rejectWithValue('something went wrong');
        }
    }
)

export const getNewProductsHome = createAsyncThunk(
    'product/getNewProductsHome',
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(newProductsHomeUrl)
            return data;
        } catch (error) {
            rejectWithValue('something went wrong');
        }
    }
)

export const getCategoriesList = createAsyncThunk(
    'product/getCategoriesList',
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(categoriesListUrl);
            // console.log(data["data"]);
            return data["data"];
        } catch (error) {
            rejectWithValue('something went wrong')
        }
    }
)

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {},
    extraReducers: {
        [createComment.pending]: (state,) => {
            state.isLoading = true;
        },
        [createComment.fulfilled]: (state,) => {
            state.isLoading = false;
            state.commentMessage = "created";
            state.isSuccess = true;
        },
        [createComment.rejected]: (state) => {
            state.isLoading = false;
            state.commentMessage = "error";
            state.isSuccess = false;
        },

        [getFilterProducts.pending]: (state) => {
            state.isLoading = true;
        },
        [getFilterProducts.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.filterProducts = payload;
            state.isSuccess = true;
        },
        [getFilterProducts.rejected]: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getSingleProduct.pending]: (state) => {
            state.isProductLoading = true;
        },
        [getSingleProduct.fulfilled]: (state, { payload }) => {
            state.isProductLoading = false;
            state.singleProduct = payload;
            state.isSuccess = true;
        },
        [getSingleProduct.rejected]: (state) => {
            state.isProductLoading = false;
            state.isSuccess = false;
        },

        [getCurrentCourse.pending]: (state) => {
            state.isLoading = true;
        },
        [getCurrentCourse.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.course = payload;
            state.isSuccess = true;
        },
        [getCurrentCourse.rejected]: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getHomePageProducts.pending]: (state) => {
            state.isLoading = true;
        },
        [getHomePageProducts.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.homeProducts = payload;
            state.isSuccess = true;
        },
        [getHomePageProducts.rejected]: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getCategoriesList.pending]: (state) => {
            state.isLoading = true;
        },
        [getCategoriesList.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.categories = payload;
            state.isSuccess = true;
        },
        [getCategoriesList.rejected]: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getMainProducts.pending]: (state) => {
            state.isLoading = true;
        },
        [getMainProducts.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.mainProducts = payload;
            state.isSuccess = true;
        },
        [getMainProducts.rejected]: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getNewProductsHome.pending]: (state) => {
            state.isLoading = true;
        },
        [getNewProductsHome.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.newProductsHome = payload.data;
            state.isSuccess = true;
        },
        [getNewProductsHome.rejected]: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default productSlice.reducer;