// get product discount price
export const getDiscountPrice = (price, discount) => {
    return discount && discount > 0 ? parseInt(price - price * (discount / 100)) : null;
};

export const toggleShopTopFilter = e => {
    const shopTopFilterWrapper = document.querySelector(
        "#product-filter-wrapper"
    );
    shopTopFilterWrapper.classList.toggle("active");
    if (shopTopFilterWrapper.style.height) {
        shopTopFilterWrapper.style.height = null;
    } else {
        shopTopFilterWrapper.style.height =
            shopTopFilterWrapper.scrollHeight + "px";
    }
    e.currentTarget.classList.toggle("active");
};