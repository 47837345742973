import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { tm, ru, en } from "../../locales/langs";

const ShopCategories = () => {
    const [selected, setSelected] = useState(null)
    const { categories } = useSelector((state) => state.homePage)
    const { i18n } = useTranslation();

    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null);
        }

        setSelected(i);
    }

    // console.log(categories)

    useEffect(() => {
        const offCanvasNav = document.querySelector("#offcanvas-navigation");
        const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");
        // const anchorLinks = offCanvasNav.querySelectorAll("a");

        for (let i = 0; i < categories.length; i++) {
            offCanvasNavSubMenu[i].insertAdjacentHTML(
                "beforebegin",
                "<span class='menu-expand'><i></i></span>"
            )
        }

        const menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
        const nummenuExpand = menuExpand.length;

        for (let i = 0; i < nummenuExpand; i++) {
            menuExpand[i].addEventListener("click", e => {
                sideMenuExpand(e)
            })
        }
    });

    const sideMenuExpand = e => {
        e.currentTarget.parentElement.classList.toggle("active");
    };



    return (
        <div className="sidebar-widget">

            <h4 
                className="pro-sidebar-title" 
                style={{
                    marginBottom: "24px", 
                    textTransform: "uppercase",
                    textDecoration: "underline"
                }}
            >
                {i18n.language === "en"
                    ? en.categories
                    : i18n.language === "ru"
                        ? ru.categories
                        : tm.categories
                }
            </h4>

            <div className="accordion">
                {categories.map((category, i) => (
                    <div className="item" key={i}>
                        <div className="atitle" onClick={() => toggle(i)}>
                            <h2 className="category-title">
                                {i18n.language === "en"
                                    ? category.title_en
                                    : i18n.language === "ru"
                                        ? category.title_ru
                                        : category.title_tm
                                }
                            </h2>
                            <span style={{fontSize: "18px"}}>{selected === i ? '-' : "+"}</span>
                        </div>
                        {category.subcategories?.map((subcat, key) => (
                            <div className={selected === i ? 'content-show' : 'content'} key={key}>
                                <Link to={`/shop-filter/?temp=false&category=` + subcat.id} className="subcategory">
                                    {i18n.language === "en"
                                        ? subcat.title_en
                                        : i18n.language === "ru"
                                            ? subcat.title_ru
                                            : subcat.title_tm
                                    }
                                </Link>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

        </div>
    )
}

export default ShopCategories;