import { Link } from "react-router-dom";

const FilterPaginator = ({ count, pageSize, search, hasQuery }) => {
    // console.log({ "Current Page is: ": currentPage })
    // console.log(hasQuery)
    // console.log({ "Paginator": hasQuery })
    const limit = pageSize;
    let pageCount = (count / limit) + 1
    console.log(pageCount)
    // if(search){
    //     pageCount = count / limit
    //     console.log(pageCount)

    // }
    const pageLink = [];

    for (let i = 1; i <= pageCount; i++) {
        pageLink.push({ "url": `limit=` + limit + `&offset=` + (limit * (i - 1)) })
    }
    // console.log(pageLink)
    return (
        <div className="pro-pagination-style text-center mt-30">
            <ul className='mb-0 mt-0'>
                {pageLink && pageLink.map((link, key) => (
                    <li className='page-item' key={key}>
                        {search ? (
                            <Link to={search + `&` + link.url}>
                                <button className='page-link'>{key + 1}</button>
                            </Link>
                        ) : (
                            <Link to={`?` + link.url}>
                                <button className='page-link'>{key + 1}</button>
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default FilterPaginator;