import { useEffect, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderTop from "../components/header/HeaderTop";
import NavMenu from "../components/header/NavMenu";
import IconGroup from "../components/header/IconGroup";
import MobileMenu from "../components/header/MobileMenu";

import Logo from "../assets/images/logo.png";

const Header = () => {
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);
    const { contactInfo } = useSelector((state) => state.info)
    const { categories } = useSelector((state) => state.products)
    const { gif_banner } = useSelector((state) => state.homePage)
    

    useEffect(() => {
        const header = document.querySelector(".sticky-bar");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    return (
        <header className={clsx("header-area clearfix")}>
            <div className={clsx("header-top-area header-padding-2 d-none d-lg-block border-none")} >
                <div className="container-fluid">
                    {/* Header Top */}
                    <HeaderTop contactInfo={contactInfo} gifBanner={gif_banner} />
                </div>
            </div>
            <div className={clsx("header-padding-2 sticky-bar header-res-padding clearfix",
                scroll > headerTop && "stick")}
                style={{backgroundColor: "#168020"}}>
                {/* style={{backgroundColor: "#168020", borderRadius: "20px"}}> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-lg-2 col-md-6 col-4">
                            {/* header logo */}
                            <div className={clsx("logo")}>
                                <Link to="/">
                                    <img alt="" src={Logo} style={{width: "160px"}}/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 d-none d-lg-block">
                            <NavMenu categories={categories}  />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-8">
                            <IconGroup contactInfo={contactInfo} />
                        </div>
                    </div>
                </div>
                {/* Mobile Menu */}
                <MobileMenu contactInfo={contactInfo} categories={categories} />
            </div>
        </header>
    )
}

export default Header;