import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from "../../../rootUrl";

const homePageInfoUrl = backendUrl + "/api/products/home-page-info/";

const initialState = {
    mainProducts: [],
    newProducts: [],
    discountProducts: [],
    specialProducts: [],
    categories: [],
    banners: [],
    brands: [],
    cashback: null,
    company_info: null,
    gif_banner: null,
    main_media: null,
    isLoading: false,
    isSuccess: false,
}

export const getHomePageInfo = createAsyncThunk(
    'main/getHomePageInfo',
    async (arg, {rejectWithValue}) => {
        try {
            const {data} = await axios.get(homePageInfoUrl);
            return data;
        } catch {
            rejectWithValue('something went wrong');
        }
    }
)

const homePageSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: {
        [getHomePageInfo.pending]:(state, {payload}) => {
            state.isLoading = true;
        },
        [getHomePageInfo.fulfilled]:(state, {payload}) => {
            state.mainProducts = payload['data']['main_products'];
            state.discountProducts = payload['data']['products_discount'];
            state.newProducts = payload['data']['products_new'];
            state.specialProducts = payload['data']['products_special'];
            state.newProducts = payload['data']['products_new'];
            state.categories = payload['data']['category_list'];
            state.banners = payload['data']['banner_list'];
            state.brands = payload['data']['brands_list'];
            state.cashback = payload['data']['cashback'];
            state.company_info = payload['data']['company_info'];
            state.gif_banner = payload['data']['gif_banner'];
            state.main_media = payload['data']['main_media'];
            state.isLoading = false;
            state.isSuccess = true;
        },
        [getHomePageInfo.rejected]:(state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default homePageSlice.reducer;