export const en = {
    "quantity": "Qty",
    "search": "Search",
    "home": "Home",
    "shop": "Shop",
    "shop_layout": "Shop Layout",
    "collection": "Collection",
    "pages": "Pages",
    "cart": "Cart",
    "checkout": "Checkout",
    "wishlist": "Wishlist",
    "compare": "Compare",
    "my_account": "My Account",
    "login_register": "Login / Register",
    "about_us": "About Us",
    "contact_us": "Contact Us",
    "404_page": "404 Page",
    "call_us":"Call Us",
    "products": "Furnitures",
    "contacts": "Contacts",
    "media": "Media",
    "stores": "Contacts",
    "choose_language": "Choose Language",
    "feautured_products": "Feautured",
    "new_products": "New",
    "sale_products": "Sale",
    "more_products": "MORE",
    "products_collection": "Furniture Collection",
    "all_rights_reserved": "All Rights Reserved",
    "profile": "PROFILE",
    "company": "COMPANY",
    "social_media": "SOCIAL MEDIA",
    "my_profile": "Profile",
    "shop_now": "Shop Now",
    "discount": "Sale",
    "days": "days",
    "hours": "hours",
    "minutes": "minutes",
    "seconds": "secs",
    "register": "Register",
    "login": "Login",
    "category": "Category",
    "brend": "Brend",
    "your_cart_items": "Your cart items",
    "image": "Image",
    "product_name": "Product Name",
    "unit_price":"Unit Price",
    "subtotal": "Subtotal",
    "total": "Total",
    "action": "action",
    "continue_shopping": "Continue Shopping",
    "clear_shopcart": "Clear Shopping Cart",
    "cart_total": "Cart Total",
    "total_products": "Total Products",
    "grand_total": "Grand Total",
    "produceed_checkout": "Proceed to Checkout",
    "your_wishlist_items": "Your wishlist items",
    "add_to_cart": "Add To Cart",
    "added": "Added",
    "clear_wishlist": "Clear Wishlist",
    "no_items_wishlist": "No items found in wishlist",
    "add_items": "Add Items",
    "product_info": "Product Info",
    "price": "Price",
    "description": "Description",
    "rating": "Rating",
    "no_items_compare": "No items found in compare",
    "logout": "Logout",
    "billing_address": "Billing Address",
    "more_media": "More Media",
    "get_in_touch": "Habarlaşmak Üçin",
    "brands": "Brands",
    "categories": "Categories",
    "orders": "Orders",
    "view_cart": "View Cart",
    "address_book": "Personal Info",
    "edit_personal_info": "Edit Personal Info",
    "edit_info": "Edit Info",
    "continue": "Continue",
    "full_name": "Full name",
    "email_address": "Email address",
    "region": "Region",
    "address": "Address",
    "mobile": "Mobile",
    "first_name": "First Name",
    "last_name": "Last Name",
    "payment_type": "Payment Type",
    "terminal": "Terminal",
    "cash": "Cash",
    "your_order": "Your order",
    "product": "Product",
    "place_order": "Place Order",
    "no_items_found": "No items found",
    "added_to_cart": "Added to cart",
    "forgot_password": "Forgot Password?",
    "username": "Username",
    "password": "Password",
    "repeat_password": "Repeat Password",
    "watch": "Watch",
    "auth_error_1": "Passwords doesn't match.",
    "auth_error_2": "Password must be at least 4 chars.",
    "auth_error_3": "Some error occured, please try again.",
    "auth_error_4": "Autherization error, please try again.",
    "status": "Status",
    "password_does_not_match": "Password does not match.",
    "sms_verification": "Sms verfication.",
    "enter_sms_code": "Please enter sms code that was sent.",
    "confirm": "Confirm",
    "code_was_sent": "Code was sent",
    "resend_sms": "Resend sms",
    "deal_of_the_day": "Deal of the Day",
    "new": "New",
    "quick_view": "Quick View",
    "added_to_wishlist": "Added to wishlist",
    "add_to_wishlist": "Add to wishlist",
    "added_to_compare": "Added to compare",
    "add_to_compare": "Add to compare",
    "related_products": "Related Products",
    "additional_info": "Additional Info",
    "reviews": "Reviews",
    "add_review": "Add Review",
    "login_to_comment": "Please login to leave a comment",
    "special_product": "Special Product",
    "price_high": "Price - Low to High",
    "price_low": "Price - High to Low",
    "created_new": "Created - New to old",
    "created_old": "Created - Old to new",
    "no_products_found": "No products found",
}

export const ru = {
    "quick_view": "Быстпый просмотр",
    "new": "Новый",
    "status": "Статус",
    "view_cart": "Корзина",
    "total": "Сумма",
    "quantity": "Количество",
    "search": "Поиск",
    "home": "Главная",
    "cart": "Корзина",
    "checkout": "Заказать",
    "wishlist": "Выбронные",
    "compare": "Сравнить",
    "my_account": "Мой Аккаунт",
    "login_register": "Войти / Регистрироваться",
    "about_us": "О Нас",
    "contact_us": "Наши Контакты",
    "404_page": "404 Page",
    "call_us":"Звоните",
    "products": "Мебели",
    "contacts": "Контакты",
    "media": "Медиа",
    "stores": "Контакты",
    "choose_language": "Выберите Язык",
    "feautured_products": "Специальные",
    "new_products": "Новые",
    "sale_products": "Распродажа",
    "more_products": "ЕЩЕ",
    "products_collection": "Коллекции Мебелей",
    "all_rights_reserved": "Все Прова Защищены",
    "profile": "ПРОФИЛЬ",
    "company": "КОМПАНИЯ",
    "social_media": "СОЦИАЛЬНАЯ МЕДИЯ",
    "my_profile": "Профиль",
    "shop_now": "Купить",
    "discount": "Скидка",
    "days": "день",
    "hours": "час",
    "minutes": "минут",
    "seconds": "секунд",
    "register": "Регистрация",
    "login": "Войти",
    "category": "Категория",
    "brend": "Бренд",
    "your_cart_items": "Товары в корзине",
    "image": "Изоброжение",
    "product_name": "Имя Товара",
    "unit_price": "Цена",
    "subtotal": "Сумма Строки",
    "action": "действие",
    "continue_shopping": "Просмотр товаров",
    "clear_shopcart": "Очистить Корзину",
    "cart_total": "Сумма Корзины",
    "total_products": "Количество Товаров",
    "grand_total": "Обшая Сумма",
    "produceed_checkout": "Оформить Заказ",
    "your_wishlist_items": "Ваши Избранные",
    "add_to_cart": "Добавть в Корзину",
    "added": "Добавлено",
    "clear_wishlist": "Очистить Избранные",
    "no_items_wishlist": "Не найдено товаров в избранных",
    "add_items": "Добавить Товаров",
    "product_info": "Онфо о Товаре",
    "price": "Цена",
    "description": "Описание",
    "rating": "Рейтинг",
    "no_items_compare": "Не найдено товаров в сравнении",
    "logout": "Выйти",
    "billing_address": "Адресс Отправления",
    "more_media": "Больше Видео",
    "get_in_touch": "Свяжитесь с нами",
    "brands": "Бренды",
    "categories": "Категории",
    "orders": "Заказы",
    "address_book": "Личные Данные",
    "edit_personal_info": "Изменить Персональные Данные",
    "edit_info": "Изменить Данные",
    "continue": "Продолжить",
    "full_name": "Полное имя",
    "email_address": "Почтовый адрес",
    "region": "Регион",
    "address": "Адрес",
    "mobile": "Мобильный",
    "first_name": "Имя",
    "last_name": "Фамилия",
    "payment_type": "Способ Оплаты",
    "terminal": "Терминал",
    "cash": "Наличные",
    "your_order": "Ваш заказ",
    "product": "Товар",
    "place_order": "Оформить Заказ",
    "no_items_found": "Товаров нету",
    "added_to_cart": "Добавлено в корзину",
    "forgot_password": "Забыли пароль?",
    "username": "Имя Пользователя",
    "password": "Пароль",
    "repeat_password": "Повторить Пароль",
    "watch": "Посматреть",
    "auth_error_1": "Пароли несовпадают.",
    "auth_error_2": "Пароль должен боть не менее 4 симбола.",
    "auth_error_3": "Ошибка в отправке смс, попробуйте заново.",
    "auth_error_4": "Ошибка авторизации, попробуйте заново.",
    "password_does_not_match": "Пароль не совпал.",
    "sms_verification": "СМС подтвержение.",
    "enter_sms_code": "Вводите отправленный смс.",
    "confirm": "Подтвердить",
    "code_was_sent": "Код был отправлен",
    "resend_sms": "Отправить заново",
    "deal_of_the_day": "Акция Дня",
    "added_to_wishlist": "Добавлено в избранные",
    "add_to_wishlist": "Добавить в избранные",
    "added_to_compare": "Добавлено в сравнение",
    "add_to_compare": "Добавить в избранные",
    "related_products": "Похожие товары",
    "additional_info": "Допольнительное инфо",
    "reviews": "Коментарии",
    "add_review": "Добавить коментарии",
    "login_to_comment": "Войдите что бы оставить коментарии.",
    "special_product": "Специальный Товар",
    "price_high": "Цена - сначала низкие",
    "price_low": "Цена - сначала дорогие",
    "created_new": "Создан - сначала новые",
    "created_old": "создан - сначала старые",
    "no_products_found": "Товаров не найдено",
}

export const tm = {
    "quick_view": "Gözden geçir",
    "new": "Täze",
    "status": "Status",
    "address_book": "Şahsy maglmatlar",
    "checkout": "Sargyt Et",
    "view_cart": "Sebet",
    "total": "Jemi",
    "search": "Gözle",
    "home": "Baş Sahypa",
    "cart": "Sebet",
    "wishlist": "Halanlarym",
    "compare": "Deňeşdir",
    "my_account": "Meniň Hasabym",
    "login_register": "Ulgama gir / Hasaba Al",
    "about_us": "Biz Barada",
    "contact_us": "Salgymyz",
    "404_page": "404 Page",
    "call_us":"Jaň ediň",
    "products": "Mebeller",
    "contacts": "Salgymyz",
    "media": "Media",
    "stores": "Salgylarymyz",
    "choose_language": "Dili Saýlaň",
    "feautured_products": "Ýörite",
    "new_products": "Täze",
    "sale_products": "Arzanladyş",
    "more_products": "ÝENE",
    "products_collection": "Mebeller Kolleksiýasy",
    "all_rights_reserved": "Ähli Haklar Goralan",
    "profile": "PROFIL",
    "company": "KÄRHANA",
    "social_media": "SOSIAL MEDIA",
    "my_profile": "Profilim",
    "shop_now": "Satyn al",
    "discount": "skidka",
    "days": "gün",
    "hours": "sagat",
    "minutes": "minut",
    "seconds": "sekund",
    "register": "Agza Bol",
    "login": "Ulgama gir",
    "category": "Kategoriýa",
    "brend": "Brend",
    "your_cart_items": "Sebetdäki harytlaryňyz",
    "product_name": "Haryt Ady",
    "unit_price":"Bahasy",
    "quantity": "Sany",
    "subtotal": "Setir Jemi",
    "action": "hereket",
    "continue_shopping": "Harytlar Sahypasyna",
    "clear_shopcart": "Sebedi Boşat",
    "cart_total": "Sebet Jemi",
    "total_products": "Harytlar Jemi",
    "grand_total": "Jemi Summa",
    "produceed_checkout": "Sargyt Etmek",
    "your_wishlist_items": "Siziň Saýlanlaryňyz",
    "add_to_cart": "Sebede Goş",
    "added": "Goşuldy",
    "clear_wishlist": "Saýlananlary Boşat",
    "no_items_wishlist": "Saýlanalar harytlar tapylmady",
    "add_items": "Haryt Goş",
    "product_info": "Haryt Barada Maglumat",
    "price": "Baha",
    "description": "Giňişleýin Maglumat",
    "rating": "Rating",
    "no_items_compare": "Deňeşdirmek üçin harytlar tapylmady",
    "logout": "Çykmak",
    "billing_address": "Ugradylmaly Adres",
    "more_media": "Beýleki Wideolar",
    "get_in_touch": "Biziň bilen habarlaşyň",
    "brands": "Brendlar",
    "categories": "Kategoriýalar",
    "orders": "Sargytlarym",
    "edit_personal_info": "Şahsy Maglumaty Üýtget",
    "edit_info": "Maglmatlary Üýtget",
    "continue": "Dowam et",
    "full_name": "Doly adyňyz",
    "email_address": "Email adres",
    "region": "Welaýat",
    "address": "Adres",
    "mobile": "Telefon belgi",
    "image": "Surat",
    "first_name": "Adyňyz",
    "last_name": "Familiýaňyz",
    "payment_type": "Töleg Görnüşi",
    "terminal": "Terminal",
    "cash": "Nagt",
    "your_order": "Sargydyňyz",
    "product": "Haryt",
    "place_order": "Sargydy tamamlamak",
    "no_items_found": "Haryt ýok",
    "added_to_cart": "Sebede goşuldy",
    "forgot_password": "Açar sözi unutdyňyzmy?",
    "username": "Ulanyjy ady",
    "password": "Açar söz",
    "repeat_password": "Açar sözi gaýtala",
    "watch": "Gör",
    "auth_error_1": "Açar sözler gabat gelenok.",
    "auth_error_2": "Açar sözüň uzynlygy 4-den uzyn bolmaly.",
    "auth_error_3": "SMS ugradyp bolmady, täzeden synanşyň.",
    "auth_error_4": "Ulgama girip bolmady, täzeden synanşyň.",
    "password_does_not_match": "Açar söz gabat gelenok.",
    "sms_verification": "Sms tassyklama.",
    "enter_sms_code": "Sms arkaly ugradylan kody giriziň.",
    "confirm": "Tassykla",
    "code_was_sent": "Sms kod ugradyldy",
    "resend_sms": "Täzeden ugrat",
    "deal_of_the_day": "Günüň pursady",
    "added_to_wishlist": "Halananlara goşulan",
    "add_to_wishlist": "Halananlara goş",
    "added_to_compare": "Deňeşdirmä goşulan",
    "add_to_compare": "Deňeşdirmä goş",
    "related_products": "Meňzeş harytlar",
    "additional_info": "Goşmaça maglumate",
    "reviews": "Teswirler",
    "add_review": "Teswir goş",
    "login_to_comment": "Teswir goşmak üçin ulgama gir.",
    "special_product": "Ýörite Haryt",
    "price_high": "Baha - arzanlar ilki",
    "price_low": "Baha - gymmatlar ilki",
    "created_new": "Döredilen - täzeler ilki",
    "created_old": "Döredilen - köneler ilki",
    "no_products_found": "Haryt tapylmady",
}  