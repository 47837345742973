import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from "../../../rootUrl";

const aboutInfoUrl = backendUrl + "/api/products/about-us/";

const initialState = {
    aboutInfo: {},
    isLoading: true,
    isSuccess: false,
}

export const getAboutInfo = createAsyncThunk(
    "info/getAboutInfo",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(aboutInfoUrl);
            return data["data"];
        } catch {
            rejectWithValue("something went wrong")
        }
    }
)

const aboutSlice = createSlice({
    name: "about",
    initialState,
    reducers: {},
    extraReducers: {
        [getAboutInfo.pending]: (state) => {
            state.isLoading = true;
        },
        [getAboutInfo.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.aboutInfo = payload;
            state.isSuccess = true;
        },
        [getAboutInfo.rejected]: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default aboutSlice.reducer;