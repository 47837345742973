import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from "../../../rootUrl";

const mainMediaUrl = backendUrl + "/api/products/main-media/";
const allMedaiUrl = backendUrl + "/api/products/media-list-web/"

const initialState = {
    mainMedia: [],
    allMedia:[],
    isLoading: false,
    isSuccess: false,
};

export const getMainMedia = createAsyncThunk(
    "media/getMainMedia",
    async (arg, {rejectWithValue}) => {
        try{
            const {data} = await axios.get(mainMediaUrl);
            return data["data"];
        }catch{
            rejectWithValue("something went wrong")
        }
    }
)

export const getAllMedia = createAsyncThunk(
    "media/getAllMedia",
    async (arg, {rejectWithValue}) => {
        try{
            const {data} = await axios.get(allMedaiUrl);
            return data['data']
        }catch{
            rejectWithValue("something went wrong")
        }
    }
)

const mediaSlice = createSlice({
    name: "media",
    initialState,
    reducers: {},
    extraReducers: {
        [getAllMedia.pending]:(state, {payload}) => {
            state.isLoading = true;
        },
        [getAllMedia.fulfilled]:(state, {payload}) => {
            state.isLoading = false;
            state.allMedia = payload;
            state.isSuccess = true
        },
        [getAllMedia.rejected]:(state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getMainMedia.pending]:(state, {payload}) => {
            state.isLoading = true;
        },
        [getMainMedia.fulfilled]:(state, {payload}) => {
            state.isLoading = false;
            state.mainMedia = payload;
            state.isSuccess = true
        },
        [getMainMedia.rejected]:(state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default mediaSlice.reducer;