import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import clsx from "clsx";
import MenuCart from "./MenuCart";
import { logoutUser } from "../../store/features/auth/authSlice";
import { en, tm, ru } from "../../locales/langs";
import { useTranslation } from "react-i18next";

const IconGroup = () => {
    const { compareItems } = useSelector((state) => state.compare);
    const { wishlistItems } = useSelector((state) => state.wishlist);
    const { cartItems } = useSelector((state) => state.cart);
    const { isAuthenticated } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const handleClick = e => {
        e.currentTarget.nextSibling.classList.toggle("active");
    };

    const [searchTitle, setSearchTitle] = useState("")

    const triggerMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            "#offcanvas-mobile-menu"
        );
        offcanvasMobileMenu.classList.add("active");
    };

    const handleInputChange = (event) => {
        const title = event.target.value;
        setSearchTitle(title)
    }

    return (
        <div className={clsx("header-right-wrap")}>
            {/* Header Search */}
            <div className="same-style header-search d-none d-lg-block">
                <button className="search-active" onClick={e => handleClick(e)}>
                    <i className="pe-7s-search" style={{ color: "white" }} />
                </button>
                <div className="search-content">
                    <form>
                        <input
                            value={searchTitle}
                            onChange={e => handleInputChange(e)}
                            type="text"
                            placeholder={
                                i18n.language === "en"
                                    ? en.search
                                    : i18n.language === "ru"
                                        ? ru.search
                                        : tm.search} />
                        <button className="button-search">
                            <Link to={`/shop-filter/?title_` + i18n.language + "=" + searchTitle}>
                                <i className="pe-7s-search" style={{ color: "white" }} />
                            </Link>
                        </button>
                    </form>
                </div>
            </div>
            {/* User Icons */}
            <div className="same-style header-compare">
                <button
                    className="account-setting-active"
                    onClick={e => handleClick(e)}
                    style={{ color: "white" }}
                >
                    <i className="pe-7s-user-female" />
                </button>
                <div className="account-dropdown">
                    <ul>
                        {isAuthenticated ? (
                            <>
                                <li>
                                    <Link to={"#"} onClick={() => dispatch(logoutUser())}>
                                        {i18n.language === 'en'
                                            ? en.logout
                                            : i18n.language === "ru"
                                                ? ru.logout
                                                : tm.logout
                                        }
                                    </Link>
                                </li>
                                <li>
                                    <Link to={process.env.PUBLIC_URL + "/account"}>
                                        {i18n.language === 'en'
                                            ? en.my_account
                                            : i18n.language === "ru"
                                                ? ru.my_account
                                                : tm.my_account
                                        }
                                    </Link>
                                </li>
                                <li>
                                    <Link to={process.env.PUBLIC_URL + "/orders"}>
                                        {i18n.language === 'en'
                                            ? en.orders
                                            : i18n.language === "ru"
                                                ? ru.orders
                                                : tm.orders
                                        }
                                    </Link>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <Link to={process.env.PUBLIC_URL + "/login-register"}>
                                        {i18n.language === 'en'
                                            ? en.register
                                            : i18n.language === "ru"
                                                ? ru.register
                                                : tm.register
                                        }
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/login-register"}>
                                        {i18n.language === 'en'
                                            ? en.login
                                            : i18n.language === "ru"
                                                ? ru.login
                                                : tm.login
                                        }
                                    </Link>
                                </li>
                            </>

                        )}

                    </ul>
                </div>
            </div>
            <div className="same-style header-compare">
                <Link to="/compare">
                    <i className="pe-7s-shuffle" style={{ color: "white" }} />
                    <span className="count-style">
                        {compareItems && compareItems.length ? compareItems.length : 0}
                    </span>
                </Link>
            </div>
            <div className="same-style header-compare">
                <Link to="/wishlist">
                    <i className="pe-7s-like" style={{ color: "white" }} />
                    <span className="count-style">
                        {wishlistItems && wishlistItems.length ? wishlistItems.length : 0}
                    </span>
                </Link>
            </div>
            <div className="same-style cart-wrap">
                <button className="icon-cart" onClick={e => handleClick(e)}>
                    <i className="pe-7s-shopbag" style={{ color: "white" }} />
                    <span className="count-style">
                        {cartItems && cartItems.length ? cartItems.length : 0}
                    </span>
                </button>
                {/* menu cart */}
                <MenuCart />
            </div>
            <div className="same-style mobile-off-canvas d-block d-lg-none">
                <button
                    className="mobile-aside-button"
                    onClick={() => triggerMobileMenu()}
                >
                    <i className="pe-7s-menu" style={{ color: "white" }} />
                </button>
            </div>
        </div>
    )
}

export default IconGroup;