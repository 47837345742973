import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from "../../../rootUrl";

const gifBannerUrl = backendUrl + "/api/products/gif-banner/";
const mainBannerUrl = backendUrl + "/api/products/banners/";
const brandListUrl = backendUrl + "/api/products/brands/";
const designSamplesUrl = backendUrl + "/api/products/design-samples/";

const initialState = {
    gifBanner: {},
    mainBanner: [],
    designSamples: [],
    brandList: [],
    isLoading: true,
    isSuccess: false,
}

export const getDesignSamples = createAsyncThunk(
    'info/getDesignSamples',
    async (arg, {rejectWithValue}) => {
        try{
            const {data} = await axios.get(designSamplesUrl);
            return data['data'];
        }catch{
            rejectWithValue('something went wrong');
        }
    }
)

export const getBrandList = createAsyncThunk(
    'info/getBrandList',
    async (arg, {rejectWithValue}) => {
        try{
            const {data} = await axios.get(brandListUrl);
            return data["data"];
        }catch{
            rejectWithValue('something went wrong');
        }
    }
);

export const getGifBanner = createAsyncThunk(
    'info/getGifBanner',
    async (arg, {rejectWithValue}) => {
        try{
            const {data} = await axios.get(gifBannerUrl);
            return data;
        }catch{
            rejectWithValue('something went wrong');
        }
    }
);

export const getMainBanner = createAsyncThunk(
    "info/getMainBanner",
    async (arg, {rejectWithValue}) => {
        try{
            const {data} = await axios.get(mainBannerUrl);
            return data["data"];
        }catch{
            rejectWithValue("something went wrong")
        }
    }
);



const bannerSlice = createSlice({
    name: "banners",
    initialState,
    reducers: {},
    extraReducers: {
        [getDesignSamples.pending]:(state, {payload}) => {
            state.isLoading = true;
        },
        [getDesignSamples.fulfilled]:(state, {payload}) => {
            state.isLoading = false;
            state.designSamples = payload;
            state.isSuccess = true;
        },
        [getDesignSamples.rejected]:(state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getBrandList.pending]:(state, {payload}) => {
            state.isLoading = true;
        },
        [getBrandList.fulfilled]:(state, {payload}) => {
            state.isLoading = false;
            state.brandList = payload;
            state.isSuccess = true;
        },
        [getBrandList.rejected]:(state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getGifBanner.pending]:(state, {payload}) => {
            state.isLoading = true;
        },
        [getGifBanner.fulfilled]:(state, {payload}) => {
            state.isLoading = false;
            state.gifBanner = payload.data;
            state.isSuccess = true;
        },
        [getGifBanner.rejected]:(state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getMainBanner.pending]:(state, {payload}) => {
            state.isLoading = true;
        },
        [getMainBanner.fulfilled]:(state, {payload}) => {
            state.isLoading = false;
            state.mainBanner = payload;
            state.isSuccess = true;
        },
        [getMainBanner.rejected]:(state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default bannerSlice.reducer;