import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { en, ru, tm } from "../../locales/langs";
import { useTranslation } from "react-i18next";

const TopBarFilter = () => {
    const { i18n } = useTranslation();
    const [byPrice, setByPrice] = useState(false)
    const [byCreated, setByCreated] = useState(false)
    const navigate = useNavigate();
    let { search } = useLocation();

    const charIndex1 = search.indexOf("&byprice")
    const charIndex2 = search.indexOf("&bycreated")
    let finalSearch = search;
    if (charIndex1 > 0) {
        finalSearch = search.substring(0, charIndex1)
    }
    if (charIndex2 > 0) {
        finalSearch = search.substring(0, charIndex2)
    }

    const handleChangePrice = event => {
        setByPrice(event.target.value);
        navigate(finalSearch + "&byprice=" + byPrice);
    };
    const handleChangeCreated = event => {
        setByCreated(event.target.value);
        navigate(finalSearch + "&bycreated=" + byCreated);
    };
    return (
        <Fragment>
            <div className="shop-top-bar mb-35">
                <div className="select-shoing-wrap" style={{ alignItems: "center" }}>
                    <div className="shop-select">
                        <select onChange={handleChangePrice} value={byPrice}>
                            <option value={true}>
                                {i18n.language === "en"
                                    ? en.price_high
                                    : i18n.language === "ru"
                                        ? ru.price_high
                                        : tm.price_high}
                            </option>
                            <option value={false}>
                                {i18n.language === "en"
                                    ? en.price_low
                                    : i18n.language === "ru"
                                        ? ru.price_low
                                        : tm.price_low}
                            </option>
                        </select>
                    </div>
                    <div className="shop-select">
                        <select onChange={handleChangeCreated} value={byCreated}>
                            <option value={true}>
                                {i18n.language === "en"
                                    ? en.created_new
                                    : i18n.language === "ru"
                                        ? ru.created_new
                                        : tm.created_new}
                            </option>
                            <option value={false}>
                                {i18n.language === "en"
                                    ? en.created_old
                                    : i18n.language === "ru"
                                        ? ru.created_old
                                        : tm.created_old}
                            </option>
                        </select>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default TopBarFilter;