import React from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { backendUrl } from "../../rootUrl";
import { en, ru, tm } from "../../locales/langs";

const ShopBrands = ({ search }) => {
    const { brands } = useSelector((state) => state.homePage)
    const { i18n } = useTranslation();

    return (
        <div className="sidebar-widget mt-50">
            <h4 className="pro-sidebar-title mb-30">
                {i18n.language === "en"
                    ? en.brands
                    : i18n.language === "ru"
                        ? ru.brands
                        : tm.brands} </h4>

            {brands ? (
                <div className='row'>
                    {brands.map((brand, key) => {
                        return (
                            <div className='col-4 col-md-6 mb-12' key={key}>
                                <Link to={search + `&brand=` + brand.id}>
                                    <img src={backendUrl + brand.image} alt="" className='brands-filter' />
                                </Link>
                            </div>
                        )
                    })}
                </div>
            ) : ("")}

        </div>
    )
}

export default ShopBrands