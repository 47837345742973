import React from "react";
import { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./helpers/scroll-top";
import { getMainProducts, getCategoriesList, getCurrentCourse } from "./store/features/product/productSlice";
import ShopFilter from "./pages/ShopFilter";
import { getHomePageInfo } from "./store/features/main/mainSlice";

const Home = lazy(() => import("./pages/HomePage"));
const ProductDetail = lazy(() => import("./pages/ProductDetail"));
const Cart = lazy(() => import("./pages/Cart"));
const Wishlist = lazy(() => import('./pages/Wishlist'));
const Compare = lazy(() => import('./pages/Compare'));
const LoginRegister = lazy(() => import('./pages/LoginRegister'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Media = lazy(() => import('./pages/MediaPage'));
const Shops = lazy(() => import('./pages/Shops'));
const Brands = lazy(() => import('./pages/Brands'));
const About = lazy(() => import('./pages/AboutUs'));
const Account = lazy(() => import('./pages/Account'));
const Orders = lazy(() => import('./pages/Orders'));

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMainProducts());
        dispatch(getCategoriesList());
        dispatch(getCurrentCourse());
        dispatch(getHomePageInfo());
    }, []);

    return (
        <Router>
            <ScrollToTop>
                <Suspense
                    fallback={
                        <div className="flone-preloader-wrapper">
                            <div className="flone-preloader">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    }
                >
                    <Routes>
                        {/* Home Page */}
                        <Route path="/" element={<Home/>} />

                        {/* Shop Filter Page */}
                        <Route path="/shop-filter" element={<ShopFilter />} />

                        {/* Product Detail Page */}
                        <Route path="/product-detail/:id" element={<ProductDetail />} />

                        {/* Cart Page */}
                        <Route path="/cart" element={<Cart />} />

                        {/* Wishlist Page */}
                        <Route path="/wishlist" element={<Wishlist />} />

                        {/* Compare Page */}
                        <Route path="/compare" element={<Compare />} />

                        {/* Login and Register Page */}
                        <Route path="/login-register" element={<LoginRegister />} />

                        {/* Checkout Page */}
                        <Route path="/checkout" element={<Checkout />} />

                        {/* Media Page */}
                        <Route path="/media" element={<Media />} />

                        {/* Shops Page */}
                        <Route path="/contacts" element={<Shops />} />

                        {/* Brands Page */}
                        <Route path="/brands" element={<Brands />}/>

                        {/* About us Page */}
                        <Route path="/about-us" element={<About/>} />

                        {/* Account Page */}
                        <Route path="/account" element={<Account/>} />

                        {/* Orders Page */}
                        <Route path="/orders" element={<Orders/>} />

                    </Routes>
                </Suspense>
            </ScrollToTop>
        </Router>
    );
}

export default App;
