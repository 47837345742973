import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from "../../../rootUrl";

const contactsInfoUrl = backendUrl + "/api/products/contacts/";
const cashbackUrl = backendUrl + "/api/products/cashback/";
const storesUrl = backendUrl + "/api/products/stores/";

const initialState = {
    contactInfo: [],
    cashbackInfo:[],
    stores:[],
    isLoading: false,
    isSuccess: false,
};

export const getStoresInfo = createAsyncThunk(
    "info/getStoresInfo",
    async (arg, {rejectWithValue}) => {
        try{
            const {data} = await axios.get(storesUrl);
            return data
        }catch(error){
            rejectWithValue("something went wrong");
        }
    }
)

export const getContactsInfo = createAsyncThunk(
    "info/getContactsInfo",
    async (arg, {rejectWithValue}) => {
        try{
            const {data} = await axios.get(contactsInfoUrl);
            return data;
        }catch(error){
            rejectWithValue("something went wrong")
        }
    }
)

export const getCashbackInfo = createAsyncThunk(
    "info/getCashbackInfo",
    async (arg, {rejectWithValue}) => {
        try{
            const {data} = await axios.get(cashbackUrl)
            return data["data"];
        }catch{
            rejectWithValue("something went wrong")
        }
    }
)

const infoSlice = createSlice({
    name: "info",
    initialState,
    reducers:{},
    extraReducers: {
        [getStoresInfo.pending]:(state) => {
            state.isLoading = true;
        },
        [getStoresInfo.fulfilled]:(state, {payload}) => {
            state.isLoading = false;
            state.stores = payload.data;
            state.isSuccess = true
        },
        [getStoresInfo.rejected]:(state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getContactsInfo.pending]:(state) => {
            state.isLoading = true;
        },
        [getContactsInfo.fulfilled]:(state, {payload}) => {
            state.isLoading = false;
            state.contactInfo = payload.data;
            state.isSuccess = true
        },
        [getContactsInfo.rejected]:(state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getCashbackInfo.pending]:(state) => {
            state.isLoading = true;
        },
        [getCashbackInfo.fulfilled]:(state, {payload}) => {
            state.isLoading = false;
            state.cashbackInfo = payload;
            state.isSuccess = true
        },
        [getCashbackInfo.rejected]:(state) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default infoSlice.reducer;