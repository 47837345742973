import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from "../../../rootUrl";

const loginUrl = backendUrl + "/api/auth/login/";
const registerUrl = backendUrl + "/api/auth/register/";
const registerMobileUrl = backendUrl + "/api/auth/register-mobile/";
const verifySMSUrl = backendUrl + "/api/auth/verify-code/";
const updateProfileUrl = backendUrl + "/api/auth/profile-create/";
const resendSmsUrl = backendUrl + "/api/auth/resend-sms/";

const initialState ={
    token: [],
    username: "",
    isAuthenticated: false,
    isLoading: false,
    isSmsSent: false,
    successMessage: "",
    smsResponse: "",
    verifyResponse: "",
    verifyMessage: "",
    profile: [],
    loginError: ""
}

export const updateProfile = createAsyncThunk(
    "auth/updateProfile",
    async ({token, profileData}, { rejectWithValue }) => {
        try{
            var config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + token
                }
            };
            const {data} = await axios.put(
                updateProfileUrl,
                profileData,
                config
            )
            return data
        }catch{
            rejectWithValue('something went wrong')
        }
    }
)

export const resendSms = createAsyncThunk(
    "auth/resendSms",
    async ({mobile}, {ejectWithValue}) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const {data} =await axios.post(
            resendSmsUrl,
            {mobile},
            config
        );
        return data
    }

)

export const verifySms = createAsyncThunk(
    "auth/verifySms",
    async({mobile, sms_code}, {ejectWithValue}) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const {data} = await axios.post(
            verifySMSUrl, 
            {mobile, sms_code},
            config
        );
        return {'data': data, "username": mobile};
    }
)

export const mobileRegister = createAsyncThunk(
    "auth/mobileRegister",
    async({mobile}, {rejectWithValue}) => {
        try{
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const {data} = await axios.post(
                registerMobileUrl, 
                {mobile},
                config
            );
            return {'data': data, "username": mobile};
        }catch{
            rejectWithValue('something went wrong')
        }
    }
)

export const userRegister = createAsyncThunk(
    "auth/register",
    async({username, password}, {rejectWithValue}) => {
        try{
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const {data} = await axios.post(
                registerUrl, 
                {username, password},
                config
            );
            return data;
        }catch (err){
            if(!err.response){
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const userLogin = createAsyncThunk(
    "auth/login",
    async({username, password}, {rejectWithValue}) => {
        username = username.slice(-8)
        try{
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const {data} = await axios.post(
                loginUrl, 
                {username, password},
                config
            );
            return data;
        }catch (err){
            if(!err.response){
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logoutUser(state){
            state.token = [];
            state.isAuthenticated = false;
            state.smsResponse = "";
            state.username = "";
            state.isLoading = false;
            state.successMessage = "";
            state.profile = [];
            state.verifyResponse = "";
            state.verifyMessage= "";
            state.isSmsSent = false;
            state.profile = [];
            state.loginError = "";
        }
    },
    extraReducers: {
        [updateProfile.pending]: (state) => {
            state.isLoading = true;
        },
        [updateProfile.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.profile = payload.data;
        },
        [updateProfile.rejected]: (state) => {
            state.isLoading = false;
        },

        [resendSms.pending]: (state) => {
            state.isLoading = true;
            state.verifyMessage = "";
            state.verifyResponse = "";
            state.username = "";
            state.isSmsSent = false;
        },
        [resendSms.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSmsSent = true;
        },
        [resendSms.rejected]: (state) => {
            state.isLoading = false;
            state.isSmsSent = false;
        },

        [verifySms.pending]: (state) => {
            state.isLoading = true;
            state.verifyMessage = "";
            state.verifyResponse = "";
            state.username = "";
        },
        [verifySms.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.verifyMessage = payload.data.message;
            state.verifyResponse = payload.data.response;
            state.username = payload.username;
        },
        [verifySms.rejected]: (state) => {
            state.isLoading = false;
        },
        
        [userRegister.pending]: (state) => {
            state.isLoading = true;
        },
        [userRegister.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.token = payload.data.token;
            state.isAuthenticated = true;
            state.username = payload.data.username;
        },
        [userRegister.rejected]: (state) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.loginError = "err401";
        },

        [userLogin.pending]: (state) => {
            state.isLoading = true;
        },
        [userLogin.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.token = payload.access;
            state.isAuthenticated = true;
        },
        [userLogin.rejected]: (state) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.loginError = "err401";
        },

        [mobileRegister.pending]: (state) => {
            state.isLoading = true;
            state.successMessage = "";
            state.smsResponse = "";
            state.username = "";
            state.verifyMessage = "";
            state.verifyResponse = "";
        },
        [mobileRegister.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.successMessage = payload.data.message;
            state.smsResponse = payload.data.response;
            state.username = payload.username;
            state.isSmsSent = true;
        },
        [mobileRegister.rejected]: (state) => {
            state.isLoading = false;
        },
}})

export const {logoutUser} = authSlice.actions;

export default authSlice.reducer;