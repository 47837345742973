import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDiscountPrice } from '../../helpers/product';
import { deleteFromCart } from "../../store/features/cart/cartSlice";
import { backendUrl } from "../../rootUrl";
import { en, tm, ru } from "../../locales/langs";

const MenuCart = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { cartItems } = useSelector((state) => state.cart);
    let cartTotalPrice = 0;
    return (
        <div className="shopping-cart-content">
            {cartItems && cartItems.length > 0 ? (
                <Fragment>
                    <ul>
                        {cartItems.map((item) => {
                            const discountedPrice = getDiscountPrice(item.get_price, item.discount)
                            discountedPrice != null
                                ? (cartTotalPrice += discountedPrice * item.quantity).toFixed(2)
                                : (cartTotalPrice += item.get_price * item.quantity).toFixed(2)

                            return (
                                <li className="single-shopping-cart" key={item.id}>
                                    <div className="shopping-cart-img">
                                        <Link to={`/product-detail/` + item.id}>
                                            <img alt="" src={backendUrl + item.main_image} className="img-fluid" />
                                        </Link>
                                    </div>
                                    <div className="shopping-cart-title">
                                        <h4>
                                            <Link to={`/product-detail/` + item.id}>
                                                {" "}{item.title_tm}{" "}
                                            </Link>
                                        </h4>
                                        <h6>{
                                            i18n.language === "en"
                                                ? en.quantity
                                                : i18n.language === "ru"
                                                    ? ru.quantity
                                                    : tm.quantity
                                        }: {item.quantity}</h6>
                                        <span>
                                            {discountedPrice !== null
                                                ? discountedPrice + ` m`
                                                : item.get_price + ` m`}
                                        </span>
                                    </div>
                                    <div className="shopping-cart-delete">
                                        <button onClick={() => dispatch(deleteFromCart(item.id))}>
                                            <i className="fa fa-times-circle" />
                                        </button>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="shopping-cart-total">
                        <h4>
                            {
                                i18n.language === "en"
                                    ? en.total
                                    : i18n.language === "ru"
                                        ? ru.total
                                        : tm.total
                            }: {" "}
                            <span className="shop-total">
                                {cartTotalPrice.toFixed(2) + ` m`}
                            </span>
                        </h4>
                    </div>

                    <div className="shopping-cart-btn btn-hover text-center">
                        <Link className="default-btn" to="/cart">
                            {
                                i18n.language === "en"
                                    ? en.cart
                                    : i18n.language === "ru"
                                        ? ru.cart
                                        : tm.cart
                            }
                        </Link>
                        <Link
                            className="default-btn"
                            to="/checkout"
                        >
                            {
                                i18n.language === "en"
                                    ? en.checkout
                                    : i18n.language === "ru"
                                        ? ru.checkout
                                        : tm.checkout
                            }
                        </Link>
                    </div>
                </Fragment>
            ) : ("")}
        </div>
    )
}

export default MenuCart;