import clsx from "clsx";
import {  useSelector } from 'react-redux';
import SingleProduct from "../product/SingleProduct";
import { Fragment } from "react";
import { en, ru, tm } from "../../locales/langs";
import { useTranslation } from "react-i18next";

const ShopProducts = ({ filterProducts }) => {
    const { i18n } = useTranslation();
    const { cartItems } = useSelector((state) => state.cart);
    const { wishlistItems } = useSelector((state) => state.wishlist);
    const { compareItems } = useSelector((state) => state.compare);
    return (
        <div className="shop-bottom-area mt-35">
            <div className={clsx("row")}>
                <Fragment>
                    {filterProducts?.results && filterProducts.results.length > 0 ? (

                        filterProducts.results.map(product => {
                            return (
                                <div className="col-xl-3 col-md-4 col-sm-6" key={product.id}>
                                    <SingleProduct
                                        key={product.id}
                                        data={product}
                                        cartItem={
                                            cartItems.find(cartItem => cartItem.id === product.id)
                                        }
                                        wishlistItem={
                                            wishlistItems.find(
                                                wishlistItem => wishlistItem.id === product.id
                                            )
                                        }
                                        compareItem={
                                            compareItems.find(
                                                compareItem => compareItem.id === product.id
                                            )
                                        }
                                    />
                                </div>
                            )

                        })
                    ) : (
                        <div>
                            <h4>
                                {i18n.language === "en"
                                    ? en.no_products_found
                                    : i18n.language === "ru"
                                        ? ru.no_products_found
                                        : tm.no_products_found}
                            </h4>
                        </div>
                    )}
                </Fragment>
            </div>
        </div>
    )
}

export default ShopProducts;