import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { en, ru, tm } from '../../locales/langs';

const MobileSearch = () => {
    const [searchTitle, setSearchTitle] = useState("")
    const { i18n } = useTranslation();

    const handleInputChange = (event) => {
        const title = event.target.value;
        setSearchTitle(title)
    }

    return (
        <div className="offcanvas-mobile-search-area">
            <form>
                <input
                    value={searchTitle}
                    onChange={e => handleInputChange(e)}
                    type="search"
                    placeholder={i18n.language === 'en'
                        ? en.search
                        : i18n.language === "ru"
                            ? ru.search
                            : tm.search
                    } />
                <button type="submit">
                    <Link to={`/shop-filter/?title_` + i18n.language + "=" + searchTitle}>
                        <i className="fa fa-search" />
                    </Link>
                </button>
            </form>
        </div>
    );
};

export default MobileSearch;
