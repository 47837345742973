import React from 'react';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import PersistProvider from './store/providers/persist-provider';
import { createRoot } from 'react-dom/client';
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./i18n";
import "./assets/scss/style.scss";
import { getContactsInfo } from './store/features/info/infoSlice';

import { getHomePageInfo } from "./store/features/main/mainSlice";

store.dispatch(getContactsInfo())
store.dispatch(getHomePageInfo())

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistProvider>
            <App />
        </PersistProvider>
    </Provider>
);