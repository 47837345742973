import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { en, ru, tm } from '../locales/langs';
import TelegramIcon from "../assets/icons/TelegramIcon";
import Logo from '../assets/images/logo.png';

const Footer = () => {
    const { i18n } = useTranslation();
    const { contactInfo } = useSelector((state) => state.info)
    return (
        <footer className="footer-area bg-gray pt-30 pb-10">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="copyright mb-30">
                            <div className="footer-logo">
                                <Link to="/">
                                    <img src={Logo} alt="" style={{width: "160px"}} />
                                </Link>
                            </div>
                            <p>
                                &copy; {new Date().getFullYear()}{" "}
                                <a
                                    href="https://rysgalmebel.com.tm"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Rysgal Mebel
                                </a>
                                .<br /> {i18n.language === 'en'
                                    ? en.all_rights_reserved
                                    : i18n.language === "ru"
                                        ? ru.all_rights_reserved
                                        : tm.all_rights_reserved
                                }
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-widget mb-30 ml-30">
                            <div className="footer-title">
                                <h3>{i18n.language === 'en'
                                    ? en.company
                                    : i18n.language === "ru"
                                        ? ru.company
                                        : tm.company
                                }</h3>
                            </div>
                            <div className="footer-list">
                                <ul>
                                    <li>
                                        <Link to={"/brands"}>{i18n.language === 'en'
                                            ? en.brands
                                            : i18n.language === "ru"
                                                ? ru.brands
                                                : tm.brands
                                        }</Link>
                                    </li>
                                    <li>
                                        <Link to={"/contacts"}>
                                            {i18n.language === 'en'
                                                ? en.stores
                                                : i18n.language === "ru"
                                                    ? ru.stores
                                                    : tm.stores
                                            }
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={process.env.PUBLIC_URL + "/media"}>
                                            {i18n.language === 'en'
                                                ? en.contacts
                                                : i18n.language === "ru"
                                                    ? ru.media
                                                    : tm.media
                                            }
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/about-us"}>
                                            {i18n.language === 'en'
                                                ? en.about_us
                                                : i18n.language === "ru"
                                                    ? ru.about_us
                                                    : tm.about_us
                                            }
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-widget mb-30 ml-50">
                            <div className="footer-title">
                                <h3><Link to={"/"}>{i18n.language === 'en'
                                    ? en.profile
                                    : i18n.language === "ru"
                                        ? ru.profile
                                        : tm.profile
                                }</Link></h3>
                            </div>
                            <div className="footer-list">
                                <ul>
                                    <li>
                                        <Link to={"/cart"}>
                                            {i18n.language === 'en'
                                                ? en.cart
                                                : i18n.language === "ru"
                                                    ? ru.cart
                                                    : tm.cart
                                            }
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/wishlist"}>{i18n.language === 'en'
                                            ? en.wishlist
                                            : i18n.language === "ru"
                                                ? ru.wishlist
                                                : tm.wishlist
                                        }</Link>
                                    </li>
                                    <li>
                                        <Link to={"/compare"}>{i18n.language === 'en'
                                            ? en.compare
                                            : i18n.language === "ru"
                                                ? ru.compare
                                                : tm.compare
                                        }</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-widget mb-30 ml-75">
                            <div className="footer-title">
                                <h3>{i18n.language === 'en'
                                    ? en.social_media
                                    : i18n.language === "ru"
                                        ? ru.social_media
                                        : tm.social_media
                                }</h3>
                            </div>
                            <div className="footer-list">
                                <ul>
                                    {contactInfo.email && (
                                        <li>
                                            <a
                                                href={"mailto:" + contactInfo.email}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fa fa-envelope"></i> {contactInfo.email}
                                            </a>
                                        </li>
                                    )}
                                    {contactInfo.instagram && (
                                        <li>
                                            <a
                                                href={"//instagram.com/" + contactInfo.instagram}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fa fa-instagram"></i> {contactInfo.instagram}
                                            </a>
                                        </li>
                                    )}
                                    {contactInfo.telegram && (
                                        <li>
                                            <a
                                                href={"https://telegram.me/" + contactInfo.telegram}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <TelegramIcon />  {contactInfo.telegram}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;