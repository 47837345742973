import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from "../../../rootUrl";

const getProfileUrl = backendUrl + "/api/auth/profile-create/";
const updateProfileUrl = backendUrl + "/api/auth/profile-create/";

const initialState = {
    isLoading: false,
    profile: [],
}

export const getProfileInfo = createAsyncThunk(
    "auth/getProfileInfo",
    async ({ token }, { rejectWithValue }) => {
        try {
            var config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + token
                }
            };
            const { data } = await axios.get(
                getProfileUrl,
                config
            )
            console.log(data)
            return data
        } catch {
            rejectWithValue('something went wrong')
        }
    }
)

export const updateProfileInfo = createAsyncThunk(
    "auth/updateProfileInfo",
    async ({ token, profileData }, { rejectWithValue }) => {
        try {
            var config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + token
                }
            };
            const { data } = await axios.put(
                updateProfileUrl,
                profileData,
                config
            )
            return data
        } catch {
            rejectWithValue("something went wrong")
        }
    }
)

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: {
        [getProfileInfo.pending]: (state) => {
            state.isLoading = true;
        },
        [getProfileInfo.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.profile = payload.data;
        },
        [getProfileInfo.rejected]: (state,) => {
            state.isLoading = false;
        },
        
        [updateProfileInfo.pending]: (state) => {
            state.isLoading = true;
        },
        [updateProfileInfo.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.profile = payload.data;
        },
        [updateProfileInfo.rejected]: (state,) => {
            state.isLoading = false;
        },

    }
})

export default profileSlice.reducer;