import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { en, ru, tm } from '../../locales/langs';

const MobileNavMenu = ({ categories }) => {
    const { i18n } = useTranslation();
    return (
        <nav className="offcanvas-navigation" id="offcanvas-navigation">
            <ul>
                <li>
                    <Link to="/">{i18n.language === 'en'
                        ? en.home
                        : i18n.language === "ru"
                            ? ru.home
                            : tm.home
                    }</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="/">{i18n.language === 'en'
                        ? en.products
                        : i18n.language === "ru"
                            ? ru.products
                            : tm.products
                    }</Link>
                    <ul className="sub-menu">
                        {categories.map((category) => (
                            <li key={category.id} className="menu-item-has-children">
                                <Link to={`/shop-filter/?main_category=` + category.id}>
                                    {i18n.language === 'en'
                                        ? category.title_en
                                        : ""
                                    }
                                    {i18n.language === 'ru'
                                        ? category.title_ru
                                        : ""
                                    }
                                    {i18n.language === 'tm'
                                        ? category.title_tm
                                        : ""
                                    }
                                </Link>
                                <ul className="sub-menu">
                                    {category.subcategories.map((subcat) => (
                                        <li key={subcat.id}>
                                            <Link to={`/shop-filter/?category=` + subcat.id}>
                                                {i18n.language === 'en'
                                                    ? subcat.title_en
                                                    : ""
                                                }
                                                {i18n.language === 'ru'
                                                    ? subcat.title_ru
                                                    : ""
                                                }
                                                {i18n.language === 'tm'
                                                    ? subcat.title_tm
                                                    : ""
                                                }
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </li>
                <li>
                    <Link to="/media">
                        {i18n.language === 'en'
                            ? en.media
                            : i18n.language === "ru"
                                ? ru.media
                                : tm.media
                        }
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        {i18n.language === 'en'
                            ? en.stores
                            : i18n.language === "ru"
                                ? ru.stores
                                : tm.stores
                        }
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default MobileNavMenu;