import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from "../../../rootUrl";

const createOrderUrl = backendUrl + "/api/order/order-create/";
const listOrderUrl = backendUrl + "/api/order/order-list/";

const initialState = {
    order: [],
    orderList: [],
    isLoading: false,
    isCreated: false,
}

export const createOrder = createAsyncThunk(
    "order/createOrder",
    async ({ orderData, token }, { rejectWithValue }) => {
        try {
            var config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            };
            const {data} = await axios.post(
                createOrderUrl,
                orderData,
                config
            );
            return data

        } catch {
            rejectWithValue('something went wrong')
        }
    }
)

export const listOrder = createAsyncThunk(
    "order/listOrder",
    async ({token}, {rejectWithValue}) => {
        console.log({"token: ": token})
        try{
            var config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            };
            console.log({"Config": config})
            const {data} = await axios.get(
                listOrderUrl,
                { headers: {"Authorization" : `Bearer ` + token} }
            );
            console.log(data)
            return data['data'];
        } catch {
            rejectWithValue('something went wrong')
        }
    }
)

const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {},
    extraReducers: {
        [createOrder.pending]: (state, { payload }) => {
            state.isLoading = true;
        },
        [createOrder.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.order = payload;
        },
        [createOrder.rejected]: (state, { payload }) => {
            state.isLoading = false;
        },
        [listOrder.pending]: (state, { payload }) => {
            state.isLoading = true;
        },
        [listOrder.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.orderList = payload;
        },
        [listOrder.rejected]: (state, { payload }) => {
            state.isLoading = false;
        },
    }
})

export default orderSlice.reducer;