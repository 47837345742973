import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDiscountPrice } from "../../helpers/product";
import { backendUrl } from "../../rootUrl";
import ProductModal from "./ProductModal";
import { addToCart } from "../../store/features/cart/cartSlice";
import { addToCompare } from "../../store/features/compare/compareSlice";
import { addToWishlist } from "../../store/features/wishlist/wishlistSlice";
import { en, ru, tm } from "../../locales/langs";

const SingleProduct = ({ data, cartItem, compareItem, wishlistItem }) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const discountedPrice = getDiscountPrice(data.get_old_price, data.discount);
    return (
        <Fragment>
            <div className="product-wrap-10 mb-25">
                <div className="product-img">
                    <Link to={`/product-detail/` + data.id}>
                        {data.main_image &&
                            <img className="default-img" src={backendUrl + data.main_image} alt=""
                                style={{ objectFit: "cover", width: "100%", height: "160px", objectPosition: "center" }} />
                        }
                        {data.image_list?.length > 0 ? (
                            <img className="hover-img" src={backendUrl + data.image_list[0].image} alt=""
                                style={{ objectFit: "cover", width: "100%", height: "160px", objectPosition: "center" }} />
                        ) : (
                            <img className="hover-img" src={backendUrl + data.main_image} alt=""
                                style={{ objectFit: "cover", width: "100%", height: "160px", objectPosition: "center" }} />
                        )}
                    </Link>
                    {data.discount > 0 || data.is_new ? (
                        <div className="product-img-badges">
                            {data.discount > 0 ? (
                                <span className="pink">-{data.discount}%</span>
                            ) : ("")}
                            {data.is_new ? <span className="purple">
                                {i18n.language === "en"
                                    ? en.new
                                    : i18n.language === "ru"
                                        ? ru.new
                                        : tm.new}
                            </span> : ""}
                        </div>
                    ) : ("")}
                    <div className="product-action-2">
                        <button
                            onClick={() => dispatch(addToCart(data))}
                            className={cartItem !== undefined && cartItem.quantity > 0
                                ? "active"
                                : ""}
                            disabled={cartItem !== undefined && cartItem.quantity > 0}
                            title={cartItem !== undefined ? (
                                i18n.language === "en"
                                    ? en.added_to_cart
                                    : i18n.language === "ru"
                                        ? ru.added_to_cart
                                        : tm.added_to_cart
                            )
                                : (
                                    i18n.language === "en"
                                        ? en.add_to_cart
                                        : i18n.language === "ru"
                                            ? ru.add_to_cart
                                            : tm.add_to_cart
                                )}
                        >
                            <i className="fa fa-shopping-cart"></i>{" "}
                        </button>
                        <button onClick={() => setModalShow(true)} title={i18n.language === "en"
                            ? en.quick_view
                            : i18n.language === "ru"
                                ? ru.quick_view
                                : tm.quick_view}>
                            <i className="fa fa-eye"></i>
                        </button>
                        <button
                            className={compareItem !== undefined ? "active" : ""}
                            disabled={compareItem !== undefined}
                            title={compareItem !== undefined ? i18n.language === "en"
                                ? en.added_to_compare
                                : i18n.language === "ru"
                                    ? ru.added_to_compare
                                    : tm.added_to_compare : i18n.language === "en"
                                    ? en.add_to_compare
                                    : i18n.language === "ru"
                                        ? ru.add_to_compare
                                        : tm.add_to_compare}
                            onClick={() => dispatch(addToCompare(data))}
                        >
                            <i className="fa fa-retweet"></i>
                        </button>
                        <button
                            className={wishlistItem !== undefined ? "active" : ""}
                            disabled={wishlistItem !== undefined}
                            title={wishlistItem !== undefined ? i18n.language === "en"
                            ? en.added_to_wishlist
                            : i18n.language === "ru"
                                ? ru.added_to_wishlist
                                : tm.added_to_wishlist : i18n.language === "en"
                                ? en.add_to_wishlist
                                : i18n.language === "ru"
                                    ? ru.add_to_wishlist
                                    : tm.add_to_wishlist}
                            onClick={() => dispatch(addToWishlist(data))}
                        >
                            <i className="fa fa-heart-o" />
                        </button>
                    </div>
                </div>
                <div className="product-content-2">
                    <div className="title-price-wrap-2">
                        <h3>
                            <Link to={`/product-detail/` + data.id}>
                                {i18n.language === "en" ? (
                                    data.title_en
                                ) : (
                                    i18n.language === "ru" ? (
                                        data.title_ru
                                    ) : (
                                        i18n.language === "tm" ? (
                                            data.title_tm
                                        ) : ("")
                                    )
                                )}
                            </Link>
                        </h3>
                        <div className="price-2">
                            <Fragment>
                                {data.get_old_price > 0 ? (
                                    <span className="old">
                                        {data.get_old_price} m.
                                    </span>
                                ) : (null)}
                                <span>
                                    {discountedPrice > 0 ? (
                                        discountedPrice + " m."
                                    ) : (null)}
                                </span>
                            </Fragment>
                        </div>
                    </div>
                </div>
            </div>
            {/* Product Modal */}
            <ProductModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                product={data}
                discountedPrice={discountedPrice}
                wishlistItem={wishlistItem}
                compareItem={compareItem}
            />

        </Fragment>
    )
}

export default SingleProduct;