import { Fragment } from "react";
import Footer from "../wrappers/Footer";
import Header from "../wrappers/Header";
import ScrollToTop from "./scroll-to-top";

const MainLayout = ({children}) => {
    return (
        <Fragment>
            <Header/>
            {children}
            <Footer />
            <ScrollToTop/>
        </Fragment>
    )
}

export default MainLayout;