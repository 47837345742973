import { useTranslation } from "react-i18next";
import TelegramIcon from "../../assets/icons/TelegramIcon";
const LanguageChanger = ({contactInfo}) => {
    const { i18n } = useTranslation();
    const changeLanguageTrigger = e => {
        const languageCode = e.target.value;
        i18n.changeLanguage(languageCode);
    };

    return (
        <div className="language-currency-wrap">
            <div className="same-language-currency language-style">
                <span>
                    {i18n.language === "ru"
                        ? "Русский"
                        : i18n.language === "en"
                            ? "English"
                            : i18n.language === "tm"
                                ? "Türkmen"
                                : ""}{" "}
                    <i className="fa fa-angle-down" />
                </span>
                <div className="lang-car-dropdown">
                    <ul>
                        <li>
                            <button value="tm" onClick={e => changeLanguageTrigger(e)}>
                                Türkmen
                            </button>
                        </li>
                        <li>
                            <button value="ru" onClick={e => changeLanguageTrigger(e)}>
                                Русский
                            </button>
                        </li>
                        <li>
                            <button value="en" onClick={e => changeLanguageTrigger(e)}>
                                English
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="same-language-currency use-style">
                {/*Off Canvas Widget Social Start*/}
                <div className="off-canvas-widget-social">
                    <a href={"//instagram.com/" + contactInfo.instagram } title="Instagram">
                        <i className="fa fa-instagram"></i>
                    </a>
                    {/* Email Link */}
                    <a href={"mailto:" + contactInfo.email } title="Facebook">
                        <i className="fa fa-envelope"></i>
                    </a>
                    {/* Telegram Link */}
                    <a href={"https://telegram.me/" + contactInfo.telegram} title="Telegram">
                        <TelegramIcon />
                    </a>
                </div>
                {/*Off Canvas Widget Social End*/}
            </div>
        </div>
    )
}

export default LanguageChanger;