import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LogoTab from "../assets/images/logo_tab.png"

const SEO = ({title, titleTemplate, description}) => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {title} | {titleTemplate}
                </title>
                <meta name="description" content={description} />
                <link rel="icon" href={LogoTab} />
                <link rel="apple-touch-icon" href={LogoTab} />
            </Helmet>
        </HelmetProvider>
    )
}

SEO.propTypes = {
    title: PropTypes.string,
    titleTemplate: PropTypes.string,
    description: PropTypes.string,
}

SEO.defaultProps = {
    title: "Rysgal Mebel",
    titleTemplate: "Mebeller Dünýäsi",
    description: "Türkmenistan, Ofis Mebeller, Öý üçin mebeller",
}

export default SEO;