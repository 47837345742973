import clsx from "clsx";
import { useTranslation } from "react-i18next";
import LanguageChanger from "./LanguageChanger";
import { backendUrl } from "../../rootUrl";
import { en, ru, tm } from '../../locales/langs';

const HeaderTop = ({ contactInfo, gifBanner }) => {
    const { i18n } = useTranslation();
    return (
        <div className={clsx("header-top-wap border-bottom")}>
            <LanguageChanger contactInfo={contactInfo} />
            <div>
                {gifBanner &&
                    <img src={backendUrl + gifBanner.image} alt="gif" className="top_gif" />
                }
            </div>
            <div className="header-offer">
                <div className="same-language-currency">
                    <a href={"tel:" + contactInfo.mobile}>
                        <p>{i18n.language === 'en'
                            ? en.call_us
                            : i18n.language === "ru"
                                ? ru.call_us
                                : tm.call_us
                        } {contactInfo.mobile}</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default HeaderTop;