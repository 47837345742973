import { Fragment, useState } from "react";
import { EffectFade, Thumbs } from 'swiper';
import Swiper, { SwiperSlide } from "../../components/swiper";
import { Modal } from "react-bootstrap";
import { backendUrl } from "../../rootUrl";
import Rating from "./ProductRating";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addToCart } from "../../store/features/cart/cartSlice";
import { addToWishlist } from "../../store/features/wishlist/wishlistSlice";
import { addToCompare } from "../../store/features/compare/compareSlice";
import { en, ru, tm } from "../../locales/langs";

const ProductModal = ({ show, onHide, product, discountedPrice, wishlistItem, compareItem }) => {
    const { i18n } = useTranslation();
    const images = [{ "id": 0, "image": product.main_image }]
    images.push(...product.image_list)
    const [quantityCount, setQuantityCount] = useState(1);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const dispatch = useDispatch();

    const gallerySwiperParams = {
        spaceBetween: 10,
        loop: true,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        thumbs: { swiper: thumbsSwiper },
        modules: [EffectFade, Thumbs],
    }

    const thumbnailSwiperParams = {
        onSwiper: setThumbsSwiper,
        spaceBetween: 10,
        slidesPerView: 4,
        touchRatio: 0.2,
        freeMode: true,
        loop: true,
        slideToClickedSlide: true,
        navigation: true
    };

    const onCloseModal = () => {
        setThumbsSwiper(null)
        onHide()
    }

    return (
        <Modal show={show} onHide={onCloseModal} className="product-quickview-modal-wrapper">
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-5 col-sm-12 col-xs-12">
                        <div className="product-large-image-wrapper">
                            <Swiper options={gallerySwiperParams}>
                                {images &&
                                    images.length > 0 ? (
                                    images.map((img, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <div className="single-image">
                                                    <img
                                                        src={backendUrl + img.image}
                                                        className="img-fluid"
                                                        alt="Product"
                                                        style={{ objectFit: "cover", width: "100%", height: "200px", objectPosition: "center" }}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                ) : ("")}
                            </Swiper>
                        </div>
                        <div className="product-small-image-wrapper mt-15">
                            <Swiper options={thumbnailSwiperParams}>
                                {images &&
                                    images.length > 0 ? (
                                    images.map((img, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <div className="single-image">
                                                    <img
                                                        src={backendUrl + img.image}
                                                        className="img-fluid"
                                                        alt=""
                                                        style={{ objectFit: "cover", width: "100%", height: "50px", objectPosition: "center" }}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                ) : ("")}
                            </Swiper>
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <div className="product-details-content quickview-content">
                            <h2>
                                {i18n.language === "en" ? (
                                    product.title_en
                                ) : (i18n.language === "ru" ? (
                                    product.title_ru
                                ) : (
                                    i18n.language === "tm" ? (
                                        product.title_tm
                                    ) : ("")
                                ))}

                            </h2>
                            <div className="product-details-price">
                                {discountedPrice !== null ? (
                                    <Fragment>
                                        <span>
                                            {discountedPrice > 0 ? discountedPrice : null}
                                        </span>
                                        <span className="old">
                                            {product.get_price > 0 ? product.get_price : null}
                                            {product.get_price}
                                        </span>
                                    </Fragment>
                                ) : (
                                    product.get_price > 0 ? (
                                        <span>{product.get_price}</span>
                                    ) : (null)
                                )}
                            </div>
                            {product.rating_average && product.rating_average > 0 ? (
                                <div className="pro-details-rating-wrap">
                                    <div className="pro-details-rating">
                                        <Rating ratingValue={product.rating_average} />
                                    </div>
                                </div>
                            ) : ("")}
                            <div className="pro-details-list">
                                <p>
                                    {i18n.language === "en" ? (
                                        product.description_en
                                    ) : (
                                        i18n.language === "ru" ? (
                                            product.description_ru
                                        ) : (
                                            i18n.language === "tm" ? (
                                                product.description_tm
                                            ) : ("")
                                        )
                                    )}
                                </p>
                            </div>
                            <div className="pro-details-quality">
                                <div className="cart-plus-minus">
                                    <button
                                        className="dec qtybutton"
                                        onClick={() => setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)}
                                    >
                                        -
                                    </button>
                                    <input
                                        className="cart-plus-minus-box"
                                        readOnly
                                        type="text"
                                        value={quantityCount}
                                    />
                                    <button
                                        className="inc qtybutton"
                                        onClick={() => setQuantityCount(quantityCount + 1)}
                                    >
                                        +
                                    </button>
                                </div>
                                <div className="pro-details-cart btn-hover">
                                    <button
                                        onClick={() => dispatch(addToCart(product))}
                                    >
                                        {i18n.language === "en"
                                            ? en.add_to_cart
                                            : i18n.language === "ru"
                                                ? ru.add_to_cart
                                                : tm.add_to_cart}
                                    </button>
                                </div>
                                <div className="pro-details-wishlist">
                                    <button
                                        className={wishlistItem !== undefined ? "active" : ""}
                                        disabled={wishlistItem !== undefined}
                                        title={wishlistItem !== undefined ? i18n.language === "en"
                                        ? en.added_to_wishlist
                                        : i18n.language === "ru"
                                            ? ru.added_to_wishlist
                                            : tm.added_to_wishlist : i18n.language === "en"
                                            ? en.add_to_wishlist
                                            : i18n.language === "ru"
                                                ? ru.add_to_wishlist
                                                : tm.add_to_wishlist}
                                        onClick={() => dispatch(addToWishlist(product))}
                                    >
                                        <i className="pe-7s-like" />
                                    </button>
                                </div>
                                <div className="pro-details-compare">
                                    <button
                                        className={compareItem !== undefined ? "active" : ""}
                                        disabled={compareItem !== undefined}
                                        title={compareItem !== undefined ? i18n.language === "en"
                                        ? en.added_to_compare
                                        : i18n.language === "ru"
                                            ? ru.added_to_compare
                                            : tm.added_to_compare : i18n.language === "en"
                                            ? en.add_to_compare
                                            : i18n.language === "ru"
                                                ? ru.add_to_compare
                                                : tm.add_to_compare}
                                        onClick={() => dispatch(addToCompare(product))}
                                    >
                                        <i className="pe-7s-shuffle" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ProductModal;