import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import productReducer from './features/product/productSlice';
import infoReducer from "./features/info/infoSlice";
import bannerSlice from './features/info/bannerSlice';
import mediaSlice from './features/media/mediaSlice';
import homePageSlice from "./features/main/mainSlice";
import cartSlice from './features/cart/cartSlice';
import compareSlice from './features/compare/compareSlice';
import wishlistSlice from './features/wishlist/wishlistSlice';
import authSlice from './features/auth/authSlice';
import orderSlice from './features/order/orderSlice';
import aboutSlice from './features/info/aboutSlice';
import profileSlice from './features/auth/profileSlice';

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";

const persistConfig = {
    key: "Rysgal",
    version: 1.1,
    storage,
};

export const rootReducer = combineReducers({
    products: productReducer,
    info: infoReducer,
    banner: bannerSlice,
    media: mediaSlice,
    homePage: homePageSlice,
    cart: cartSlice,
    compare: compareSlice,
    wishlist: wishlistSlice,
    auth: authSlice,
    order: orderSlice,
    about: aboutSlice,
    profiles: profileSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

export const persistor = persistStore(store);