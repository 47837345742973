import TelegramIcon from '../../assets/icons/TelegramIcon';

const MobileWidgets = ({ contactInfo }) => {
    return (
        <div className="offcanvas-widget-area">
            <div className="off-canvas-contact-widget">
                <div className="header-contact-info">
                    <ul className="header-contact-info__list">
                        <li>
                            <i className="fa fa-phone"></i>{" "}
                            <a href={"tel://" + contactInfo.mobile}>{contactInfo.mobile}</a>
                        </li>
                        <li>
                            <i className="fa fa-envelope"></i>{" "}
                            <a href={"mailto:" + contactInfo.email}>{contactInfo.email}</a>
                        </li>
                    </ul>
                </div>
            </div>
            {/*Off Canvas Widget Social Start*/}
            <div className="off-canvas-widget-social">
                <a href={"//instagram.com/" + contactInfo.instagram} title="Instagram">
                    <i className="fa fa-instagram"></i>
                </a>
                <a href={"https://telegram.me/" + contactInfo.telegram} title="Facebook">
                <TelegramIcon />
                </a>
            </div>
            {/*Off Canvas Widget Social End*/}
        </div>
    );
};

export default MobileWidgets;