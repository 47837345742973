import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SEO from "../components/seo";
import MainLayout from "../components/MainLayout";
import ShopProducts from '../wrappers/filter/ShopProducts';
import FilterPaginator from '../wrappers/filter/FilterPaginator';
import { getFilterProducts } from '../store/features/product/productSlice';
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import ShopSidebar from '../wrappers/filter/ShopSidebar';
import TopBarFilter from '../wrappers/filter/TopBarFilter';

const ShopFilter = () => {
    const dispatch = useDispatch();
    const [hasQuery, setHasQuery] = useState(false)
    
    const [searchParams] = useSearchParams();

    let { search } = useLocation();
    const charIndex = search.indexOf("&limit")
    let finalSearch = search;
    if (charIndex > 0) {
        finalSearch = search.substring(0, charIndex)
    }

    const pageSize = 12;

    useEffect(() => {
        setHasQuery(false)
        dispatch(getFilterProducts({ search }));

        console.log({ "use effect": hasQuery })
    }, [search]);

    const { filterProducts } = useSelector((state) => state.products);
    let count = 0;
    if (filterProducts) {
        count = filterProducts.count;
    }

    return (
        <Fragment>
            <SEO
                titleTemplate="Shop Page"
                description="Shop page of flone react minimalist eCommerce template."
            />
            <MainLayout>
                <div className="shop-area pt-95 pb-100">
                    <div className='container-shop'>
                        <div className="row">
                            <div className="col-lg-2 order-2 order-lg-1">
                                {/* shop topbar filter */}
                                {/* <TopBarFilter /> */}
                                <ShopSidebar search={search} />
                            </div>
                            <div className="col-lg-10 order-1 order-lg-2">
                                <TopBarFilter />
                                {/* Product Filer Content */}
                                <ShopProducts filterProducts={filterProducts} />

                                {/* shop product pagination */}
                                <div className="pro-pagination-style text-center mt-30">
                                    <FilterPaginator search={search} hasQuery={hasQuery} count={count} pageSize={pageSize} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </MainLayout>
        </Fragment>
    )
}

export default ShopFilter;