import React from "react";
import ShopBrands from "./ShopBrands";
import ShopCategories from "./ShopCategories";
import ShopSearch from "./ShopSearch";

const ShopSidebar = ({search}) => {
    return (
        <div className="sidebar-style">
            {/* <ShopSearch /> */}

            {/* Filter By Categories */}
            <ShopCategories />

            <ShopBrands search={search} />
        </div>
    )
}

export default ShopSidebar;