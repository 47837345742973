import { useTranslation } from "react-i18next";
import {en, ru, tm} from '../../locales/langs';

const MobileLanguageChange = () => {
    const { i18n } = useTranslation();

    const changeLanguageTrigger = e => {
        const languageCode = e.target.value;
        i18n.changeLanguage(languageCode);
        closeMobileMenu();
    }

    const closeMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            "#offcanvas-mobile-menu"
        );
        offcanvasMobileMenu.classList.remove("active");
    };

    return (
        <div className="mobile-menu-middle">
            <div className="lang-curr-style">
                <span className="title mb-2">{i18n.language === 'en'
                    ? en.choose_language
                    : i18n.language === "ru"
                        ? ru.choose_language
                        : tm.choose_language
                }</span>
                <select
                    value={i18n.language}
                    onChange={changeLanguageTrigger}
                >
                    <option value="tm">Türkmen</option>
                    <option value="ru">Русский</option>
                    <option value="en">English</option>
                </select>
            </div>
        </div>
    );
};

export default MobileLanguageChange;